@import url("https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&family=Montserrat&family=Norican&display=swap");
@import url("bootstrap/dist/css/bootstrap.css");

$yellow: #f2d98d;
$pinkLight: #f2b6c6;
$pinkBright: #f25caf;
$teal: #0dd9c4;
$blue: #aee6f2;
$headerFont: "Montserrat", Arial, sans-serif;

body {
  font-family: $headerFont;
  font-size: 1.4vw;

  max-width: 100%;
}

h2 {
  font-family: "Norican", cursive;
}

h1,
h2,
h3,
h4,
h5,
h6,
nav {
  font-size: 150%;
  font-family: "Norican", cursive;
}

/* Style the header */

header {
  padding-bottom: 2vw;
  background-color: $teal;
  background-image: url("../img/diagmonds-light.png");
  background-position: right;
  color: white;
  text-align: center;
  padding-top: 2vw;

  img {
    background-color: rgba(255, 255, 255, 0.493);
    opacity: 0.9;
    margin-right: 10vw;
    /*  background-image: url('../img/uniPattern.png');*/
    padding-top: 0.25vw;
  }
  h1 {
    font-size: 500%;
    padding-top: 10vw;
    text-shadow: 0.2vw 0.2vw $pinkBright;
  }
}

.margin {
  margin-top: 0.75vw;
}

nav {
  li {
    padding: 10px;
  }

  font: $headerFont;
  .navCust {
    padding-left: 10px;
    a:hover {
      border-color: #ffbf00;
      border-top: 2px solid;
      border-bottom: 2px solid;
      font-weight: bold;
      text-decoration: none;
      color: black;
    }
    a {
      text-decoration: none;
      color: black;
    }
    .active {
      border-bottom: 2px solid;
      font-weight: bold;
    }
  }
}

.footer {
  padding: 1vw;

  .social-icon {
    margin: 1vw;
    color: $pinkBright;
    background-image: url("../img/fabric.png");
  }
}

/* header styling */
.article {
  padding-top: 1vw;
  padding-bottom: 1vw;
  background-image: url("../img/arches.png");
  background-repeat: repeat;

  .custCol:nth-of-type(odd) {
    background-color: $pinkLight;
  }
  .custCol:nth-of-type(even) {
    background-color: $blue;
  }

  p {
    text-align: justify;
    font-size: 1.3vw;
  }
}
.card {
  background-color: transparent !important;
  border: none !important;
}

.banner {
  .row > .col {
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
}

/* Style the footer */
.footer {
  background-color: $yellow;
  text-align: center;
  color: white;
}

.boxStyle {
  box-shadow: 0.1vw 0.1vw 0.1vw 0.1vw rgb(230, 230, 230);
}

.yellowShadow {
  background-color: $yellow;
  background-image: url("../img/fabric.png");
}

/* nav styling */

/* Header Image */
.circular-square {
  border-radius: 45%;
}

.sample {

  h5 {
    font-size: 2vw;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones:*/
  [class*="col"] {
    width: 100%;
  }

  h5 {
    font-size: 6vw;
  }

  .article {
    p {
      font-size: 15px;
    }
    h5 {
      font-size: 20px;
    }
  }

  main {
    .subHeading {
      font-size: 25px;
    }
    h2 {
      font-size: 7vw;
    }
    p {
      font-size: 4vw;
    }

    h1 {
      font-size: 8vw;
    }

  }
  nav li {
    font-size: 5vw;
  }
  header {
    h1 {
      font-size: 10vw;
      margin-left: 20px;
    }

    h2 {
      font-size: 4vw;
      margin-left: 20px;
    }
  }
}

.sample {
    .card-body {
      background-color: $teal;
      background-image: url('../img/diagmonds-light.png');
    }
    .card img{
      opacity: 0.75;
    }

    .card img:hover{
      opacity: 1;      
    }
  }

.text-center {
  text-align: center;
}
form {
  label {
    font-weight: bold;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
}

button[type="submit"] {
  background-color: $pinkBright;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button[type="submit"]:hover {
  background-color: #83305d;
}
